import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { animate } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { CounterProps } from './Counter.types';
import { StyledNumber, StyledCaption, StyledStack } from './styles';
import { useInView } from 'framer-motion';

export const Counter = ({ to, caption }: CounterProps): ReactJSXElement => {
  const inViewRef = useRef(null);
  const inView = useInView(inViewRef, { once: true });
  const numberRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (inView) {
      const controls = animate(0, to, {
        duration: 2,
        ease: 'easeOut',
        delay: 0.1,
        onUpdate: (value) => {
          if (numberRef.current) numberRef.current.innerHTML = value.toFixed(0);
        },
      });
      return () => controls.stop();
    }
  }, [to, inView]);

  return (
    <StyledStack ref={inViewRef}>
      <StyledNumber variant="display_md" ref={numberRef}>
        0
      </StyledNumber>
      {caption && <StyledCaption variant="strong_300">{caption}</StyledCaption>}
    </StyledStack>
  );
};
