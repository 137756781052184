import { useTheme } from '@mui/material';
import React from 'react';

import { ContactForm, Link, NameBanner } from '../../../../Rumblefish23Theme';
import type { ClickableSubtitleProps, ContactProps } from './Contact.types';
import {
  Subtitle,
  ContactSection,
  StyledWrapper,
  Title,
  InfoStack,
  ContentStack,
  SubtitleLink,
} from './styles';

const ClickableSubtitle = ({
  children,
  color,
  link,
}: ClickableSubtitleProps) => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const linkColor = theme.palette?.[`${color}Colors`]?.PLUS_2;

  return (
    <Link to={link}>
      <SubtitleLink color={linkColor} variant="regular_300">
        {children}
      </SubtitleLink>
    </Link>
  );
};

export const Contact = ({ id, title, subtitle, color }: ContactProps) => {
  return (
    <ContactSection width="normal" borderTopOn id={id}>
      <StyledWrapper>
        <NameBanner type="section" text={'Have an idea?'} color={color} />
        <ContentStack>
          <InfoStack>
            {title ? (
              <Title variant="display_md">{title}</Title>
            ) : (
              <Title variant="display_md">
                Let’s work
                <br /> together!
              </Title>
            )}

            {subtitle ? (
              <Subtitle
                variant="regular_500"
                width={{ xs: '100%', md: '50%', xl: '45%' }}>
                {subtitle}
              </Subtitle>
            ) : (
              <Subtitle
                variant="regular_500"
                width={{ xs: '100%', md: '50%', xl: '45%' }}>
                We will answer any questions you may have related to your
                startup journey!
              </Subtitle>
            )}

            <Subtitle variant="regular_300">
              Do you prefer e-mail?
              <br />
              <ClickableSubtitle
                color={color}
                link={'mailto:hello@rumblefish.com'}>
                hello@rumblefish.pl
              </ClickableSubtitle>
            </Subtitle>
          </InfoStack>
          <ContactForm color={color} />
        </ContentStack>
      </StyledWrapper>
    </ContactSection>
  );
};
