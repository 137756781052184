export const doSetShouldBeGradient = (payload: boolean) =>
  ({
    type: 'setShouldBeGradient',
    payload,
  }) as IAction;

export const doSetAnimationProps = (payload: AnimationPropsType) =>
  ({
    type: 'setAnimationProps',
    payload,
  }) as IAction;

export const doSetIsDraggingComplete = (payload: boolean) =>
  ({
    type: 'setIsDraggingComplete',
    payload,
  }) as IAction;

export const doSetValuesOnDrag = (payload: IValuesOnDrag) =>
  ({
    type: 'setValuesOnDrag',
    payload,
  }) as IAction;

export type AnimationPropsType = {
  x: number;
  y: number;
  scale: number;
};
export interface IBubbleReducerState {
  isDraggingComplete: boolean;
  shouldTriggerFloating: boolean;
  shouldBeGradient: boolean;
  animationProps: AnimationPropsType;
}

export const initialState = {
  isDraggingComplete: false,
  shouldTriggerFloating: true,
  shouldBeGradient: false,
  animationProps: {
    x: 0,
    y: 0,
    scale: 1,
  },
};

export type IValuesOnDrag = {
  isDraggingComplete: boolean;
  shouldTriggerFloating: boolean;
};

type IActionSetAnimationProps = {
  type: 'setAnimationProps';
  payload: AnimationPropsType;
};

type IActionSetIsDraggingComplete = {
  type: 'setIsDraggingComplete';
  payload: boolean;
};
type IActionSetShouldBeGradient = {
  type: 'setShouldBeGradient';
  payload: boolean;
};
type IActionSetValuesOnDrag = {
  type: 'setValuesOnDrag';
  payload: IValuesOnDrag;
};
export type IAction =
  | IActionSetShouldBeGradient
  | IActionSetValuesOnDrag
  | IActionSetAnimationProps
  | IActionSetIsDraggingComplete;

export function reducer(
  state: IBubbleReducerState,
  action: IAction
): IBubbleReducerState {
  switch (action.type) {
    case 'setAnimationProps': {
      return {
        ...state,
        animationProps: action.payload,
      };
    }
    case 'setIsDraggingComplete': {
      return {
        ...state,
        isDraggingComplete: action.payload,
      };
    }
    case 'setShouldBeGradient': {
      return {
        ...state,
        shouldBeGradient: action.payload,
      };
    }
    case 'setValuesOnDrag': {
      return {
        ...state,
        isDraggingComplete: action.payload.isDraggingComplete,
        shouldTriggerFloating: action.payload.shouldTriggerFloating,
      };
    }

    default: {
      return { ...state };
    }
  }
}
